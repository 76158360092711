<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" src="/images/contablus.png"/>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li style="display: flex;">
				<Image :src="this.$baseURL + this.$store.getters.defaultActivity.logoUrl" width="90"></Image>
				<h5 style="margin: 0px 0px 0px 5px; padding: 12px 0px;">{{ this.$store.getters.getUser.firstName + " " + this.$store.getters.getUser.lastName }}</h5>
			</li>
			<li>
				<button class="p-link layout-topbar-button" @click="logout">
					<i class="pi pi-sign-out"></i>
					<span>Salir</span>
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
// import EconActivity from './models/econActivity';
import httpRequest from './service/httpRequest';
export default {
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},
		async logout() {
            let response = await httpRequest.logout();
            if (response.status) { 
				await this.$store.dispatch('Logout');
                this.$router.push('/login')
            } else {
                this.$toast.add({severity:'error', summary: 'Error', detail:'Hemos tenido un problema al cerrar su sesión', life: 3000});
            }
		},
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>